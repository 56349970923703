import React from 'react'
import Video from '../../videos/flow_data_540p.mp4'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements'

const HeroSection = () => {
  return (
    <HeroContainer>
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        </HeroBg>  
        <HeroContent>
            <HeroH1>Play connected anywhere</HeroH1>
            <HeroP>Coming Soon...</HeroP>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
